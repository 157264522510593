import classNames from 'classnames'
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

interface Props {
  content: [ReactNode] | [ReactNode, ReactNode] | [ReactNode, ReactNode, ReactNode]
  image?: ReactNode
  variant?: 'image-first' | 'image-last' | 'no-image'
  className?: string
  bg?: ReactNode
  fullHeightContent?: boolean
  style?: any
}
const Box: FC<Props> = ({
  bg,
  content,
  image,
  className,
  variant = 'image-last',
  style,
  fullHeightContent = false,
}) => {
  const boxRef = useRef<HTMLElement>(null)
  const [isVisible, setVisible] = useState(true)
  const textContent = (
    <div
      className={classNames('flex-col p-6 relative', {
        ['py-20']: variant !== 'no-image',
        ['h-full']: fullHeightContent,
      })}
    >
      {content.map((element, index) => (
        <div
          key={index}
          className={classNames({ ['justify-self-end mt-auto']: 2 === index, ['mb-6']: index !== content.length - 1 })}
        >
          {element}
        </div>
      ))}
    </div>
  )
  const illustrationContent = image || <div></div>
  let children = [textContent, illustrationContent]

  switch (variant) {
    case 'image-first': {
      children = [illustrationContent, textContent]
      break
    }
    case 'no-image': {
      children = [textContent]
      break
    }
  }

  useEffect(() => {
    if (!boxRef.current) {
      return
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting))
    })
    observer.observe(boxRef.current)
    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current)
      }
    }
  }, [])

  return (
    <section
      ref={boxRef}
      style={style}
      className={classNames(
        'relative bg-gray-800 rounded-2xl min-h-[20rem] lg:h-[28rem] overflow-hidden group transition-[opacity, transform] duration-500',
        className,
        {
          ['lg:grid lg:grid-cols-2']: variant === 'image-first' || variant === 'image-last',
          ['opacity-100 scale-100']: isVisible,
          ['opacity-20 scale-95']: !isVisible,
        }
      )}
    >
      {bg}
      {children[0]}
      {children[1] ? children[1] : undefined}
    </section>
  )
}

export default Box
