import type { HeadFC } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { BsArrowRightShort } from 'react-icons/bs'
import { useIntl } from 'react-intl'

import AnimatedTitle from '../components/AnimatedTitle'
import Box from '../components/Box'
import Button from '../components/Button'
import ContentBox from '../components/ContentBox'
import HomeVehicleIllustration from '../components/HomeVehicleIllustration'
import { LanguageHeaders } from '../components/LanguageHeaders'
import Layout from '../components/Layout'
import Link from '../components/Link'
import Question from '../components/Question'
import { HrEmail, InfoEmail, MediaEmail } from '../constants'
import _translations from '../translations'
import { useTranslate } from '../utils/translate'

const IndexPage = () => {
  const t = useTranslate()
  // @ts-ignore
  const translations: typeof _translations['en'] = useIntl().messages
  const allFaqs = translations['faq.all']

  return (
    <Layout>
      <HomeVehicleIllustration />
      <main className="m-auto w-full lg:max-w-screen-lg grid grid-flow-row gap-20 lg:gap-52 px-4 lg:px-0">
        <section className="grid gap-8">
          <Box
            variant="image-first"
            content={[
              <h3>{t('platform.sections.1.title')}</h3>,
              <p className="subtitle text-secondary">{t('platform.sections.1.subtitle')}</p>,
              <div className="flex text-blue">
                <Link to="/contact?q=demo" className="body-1 flex items-center mr-1">
                  {t('getStarted')}
                </Link>
                <BsArrowRightShort size={24} />
              </div>,
            ]}
            image={
              <div className="flex flex-col justify-center items-center m-6">
                <StaticImage
                  alt="platform-image"
                  src="../images/platform.png"
                  quality={95}
                  layout="constrained"
                  loading="lazy"
                  placeholder="none"
                />
              </div>
            }
          />
          <Box
            variant="image-last"
            content={[
              <h3 className="text-3xl">{t('platform.sections.2.title')}</h3>,
              <p className="subtitle text-secondary">{t('platform.sections.2.subtitle')}</p>,
              <div className="flex text-blue">
                <Link to="/contact?q=demo" className="body-1 flex items-center mr-1">
                  {t('getStarted')}
                </Link>
                <BsArrowRightShort size={24} />
              </div>,
            ]}
            image={
              <div className="relative">
                <div className="lg:hidden">
                  <StaticImage
                    alt="cards-image"
                    src="../images/fuel_cards_small.png"
                    layout="constrained"
                    quality={90}
                    loading="lazy"
                    placeholder="none"
                  />
                </div>
                <div className="hidden lg:block absolute left-[10%] top-1/2 -translate-y-1/2 mt-1">
                  <StaticImage
                    alt="cards-image"
                    src="../images/fuel_cards.png"
                    layout="fixed"
                    height={680}
                    quality={95}
                    loading="lazy"
                    placeholder="none"
                  />
                </div>
              </div>
            }
          />
          <Box
            variant="image-first"
            content={[
              <h3 className="text-3xl">{t('platform.sections.3.title')}</h3>,
              <p className="subtitle text-secondary">{t('platform.sections.3.subtitle')}</p>,
              <div className="flex text-blue">
                <Link to="/contact?q=demo" className="body-1 flex items-center mr-1">
                  {t('getStarted')}
                </Link>
                <BsArrowRightShort size={24} />
              </div>,
            ]}
            image={
              <div className="flex flex-col justify-end items-center mx-6 mb-0">
                <StaticImage
                  alt="automated-tasks-image"
                  src="../images/automated_tasks.png"
                  layout="fixed"
                  width={400}
                  quality={99}
                  loading="lazy"
                  placeholder="none"
                />
              </div>
            }
          />
          <Box
            variant="image-last"
            content={[
              <h3>{t('platform.sections.4.title')}</h3>,
              <p className="subtitle text-secondary">{t('platform.sections.4.subtitle')}</p>,
              <div className="flex text-blue">
                <Link to="/contact?q=demo" className="body-1 flex items-center mr-1">
                  {t('getStarted')}
                </Link>
                <BsArrowRightShort size={24} />
              </div>,
            ]}
            image={
              <div className="mx-auto flex flex-col justify-end items-center max-w-[420px]">
                <StaticImage
                  alt="assignments-image"
                  src="../images/assignments.png"
                  quality={99}
                  layout="constrained"
                  loading="lazy"
                  placeholder="none"
                />
              </div>
            }
          />
          <Box
            variant="image-first"
            content={[
              <h3 className="text-3xl">{t('platform.sections.5.title')}</h3>,
              <p className="subtitle text-secondary">{t('platform.sections.5.subtitle')}</p>,
              <div className="flex text-blue">
                <Link to="/contact?q=demo" className="body-1 flex items-center mr-1">
                  {t('getStarted')}
                </Link>
                <BsArrowRightShort size={24} />
              </div>,
            ]}
            image={
              <div className="mx-auto flex flex-col items-center max-w-[380px]">
                <StaticImage
                  alt="mileage-image"
                  src="../images/mileage.png"
                  quality={99}
                  layout="constrained"
                  loading="lazy"
                  placeholder="none"
                />
              </div>
            }
          />

          <Box
            variant="image-last"
            content={[
              <h3 className="text-3xl">{t('platform.sections.6.title')}</h3>,
              <p className="subtitle text-secondary">{t('platform.sections.6.subtitle')}</p>,
              <div className="flex text-blue">
                <Link to="/contact?q=demo" className="body-1 flex items-center mr-1">
                  {t('getStarted')}
                </Link>
                <BsArrowRightShort size={24} />
              </div>,
            ]}
            image={
              <div className="flex flex-col justify-end max-w-[512] items-end">
                <StaticImage
                  alt="invoice-image"
                  src="../images/invoice.png"
                  quality={99}
                  layout="constrained"
                  loading="lazy"
                  placeholder="none"
                />
              </div>
            }
          />
          <Box
            variant="image-first"
            content={[
              <h3 className="text-3xl">{t('platform.sections.7.title')}</h3>,
              <p className="subtitle text-secondary">{t('platform.sections.7.subtitle')}</p>,
              <div className="flex text-blue">
                <Link to="/contact?q=demo" className="body-1 flex items-center mr-1">
                  {t('getStarted')}
                </Link>
                <BsArrowRightShort size={24} />
              </div>,
            ]}
            image={
              <div className="flex flex-col items-center">
                <StaticImage
                  alt="currencies-image"
                  src="../images/currencies.png"
                  quality={99}
                  layout="constrained"
                  width={400}
                  loading="lazy"
                  placeholder="none"
                />
              </div>
            }
          />
        </section>
        <section className="grid gap-8">
          <div id="analytics-section" className="absolute -mt-20"></div>
          {/* <AnalyticsIllustration /> */}
          <div className="z-10 grid gap-4 mb-24">
            <h4 className="text-secondary">{t('platform.headers.2.headline')}</h4>
            <AnimatedTitle
              content={translations['platform.headers.2.title']}
              highlightedWordsIndex={translations['platform.headers.2.titleHighlightIndex']}
            />
            <p className="body-1 max-w-2xl">{t('platform.headers.2.subtitle')}</p>
          </div>
          <div className="grid lg:grid-cols-12 gap-8">
            <Box
              className="lg:col-span-5"
              variant="no-image"
              content={[
                <h3 className="text-3xl">{t('platform.sections.8.title')}</h3>,
                <p className="subtitle text-secondary">{t('platform.sections.8.subtitle')}</p>,
                <div className="lg:hidden ">
                  <StaticImage
                    alt="tco-image"
                    src="../images/tco.png"
                    layout="constrained"
                    quality={95}
                    loading="lazy"
                    placeholder="none"
                  />
                </div>,
              ]}
              bg={
                <div className="hidden lg:block absolute bottom-2 right-0">
                  <StaticImage
                    alt="tco-image"
                    src="../images/tco.png"
                    layout="constrained"
                    quality={95}
                    loading="lazy"
                    placeholder="none"
                  />
                </div>
              }
            />
            <Box
              className="lg:col-span-7"
              variant="no-image"
              content={[
                <h3 className="text-3xl">{t('platform.sections.9.title')}</h3>,
                <p className="subtitle text-secondary">{t('platform.sections.9.subtitle')}</p>,
                <div className="flex justify-center mt-6">
                  <StaticImage
                    alt="gauge-image"
                    src="../images/gauge.png"
                    layout="constrained"
                    width={350}
                    quality={95}
                    loading="lazy"
                    placeholder="none"
                  />
                </div>,
              ]}
            />
          </div>
          <div className="grid lg:grid-cols-12 gap-8">
            <Box
              className="lg:col-span-7"
              variant="no-image"
              content={[
                <h3 className="text-3xl">{t('platform.sections.10.title')}</h3>,
                <p className="subtitle text-secondary">{t('platform.sections.10.subtitle')}</p>,
                <>
                  <div className="flex justify-center items-center h-full mt-16">
                    <StaticImage
                      class="flex-grow"
                      alt="mileage-image"
                      src="../images/mileage_mgmt.png"
                      quality={95}
                      layout="constrained"
                      loading="lazy"
                      placeholder="none"
                    />
                  </div>
                </>,
              ]}
            />
            <Box
              className="lg:col-span-5 border-1 border-gray-400/60"
              variant="no-image"
              fullHeightContent
              content={[
                <h3 className="text-3xl relative">{t('platform.sections.11.title')}</h3>,
                <p className="subtitle relative">{t('platform.sections.11.subtitle')}</p>,

                <Link to="/contact?q=demo" className="base-link">
                  <Button className="body-1 flex items-center relative dark:hover:bg-blue dark:hover:text-white">
                    <span className="mr-1">{t('requestDemo')}</span>
                    <BsArrowRightShort size={24} />
                  </Button>
                </Link>,
              ]}
              bg={
                <div className="absolute z-0 left-0 top-0 h-full w-full overflow-hidden">
                  <StaticImage
                    className="m-auto group-hover:scale-105 transition-transform duration-500 opacity-70"
                    alt="analytics"
                    src="../images/light_tubes.jpg"
                    layout="constrained"
                    quality={95}
                    loading="lazy"
                    placeholder="none"
                  />
                </div>
              }
            />
          </div>
        </section>
        <section className="grid gap-8">
          <div id="about-section" className="absolute -mt-20"></div>
          <div className="grid gap-4 mb-24">
            <h4 className="text-secondary">{t('platform.headers.3.headline')}</h4>
            <AnimatedTitle
              content={translations['platform.headers.3.title']}
              highlightedWordsIndex={translations['platform.headers.3.titleHighlightIndex']}
            />
            <p className="body-1">{t('platform.headers.3.subtitle')}</p>
          </div>
          <div className="grid lg:grid-cols-12 gap-8">
            <Box
              className="lg:col-span-7"
              variant="no-image"
              content={[
                <h3 className="text-3xl">{t('platform.sections.12.title')}</h3>,
                <p className="subtitle text-secondary">{t('platform.sections.12.subtitle')}</p>,
                <>
                  <div className="flex text-blue">
                    <Link to="/contact?q=demo" className="body-1 flex items-center mr-1">
                      {t('contactSales')}
                    </Link>
                    <BsArrowRightShort size={24} />
                  </div>
                  <div className="flex justify-center lg:hidden mt-8 -mb-44">
                    <StaticImage
                      alt="globe-image"
                      src="../images/globe_transparent.png"
                      quality={90}
                      layout="constrained"
                      loading="lazy"
                      placeholder="none"
                      className="max-w-[20rem]"
                    />
                  </div>
                </>,
              ]}
              bg={
                <div className="hidden lg:block absolute bottom-[-35%] lg:right-[-2%]">
                  <StaticImage
                    className="group-hover:scale-105 transition-transform duration-500 !select-none"
                    alt="globe-image"
                    src="../images/globe_transparent.png"
                    width={400}
                    quality={95}
                    loading="lazy"
                    placeholder="none"
                  />
                </div>
              }
            />
            <Box
              className="lg:col-span-5"
              variant="no-image"
              content={[
                <h3 className="text-3xl">{t('platform.sections.13.title')}</h3>,
                <p className="subtitle text-secondary">
                  {t('platform.sections.13.subtitle', {
                    mediaEmail: (
                      <a href={`mailto:${MediaEmail}`} className="text-blue subtitle">
                        {MediaEmail}
                      </a>
                    ),
                    infoEmail: (
                      <a href={`mailto:${InfoEmail}`} className="text-blue subtitle">
                        {InfoEmail}
                      </a>
                    ),
                  })}
                </p>,
              ]}
            />
          </div>
          <div className="grid lg:grid-cols-12 gap-8">
            <Box
              className="lg:col-span-4 bg-gradient-to-t
              !bg-white"
              variant="no-image"
              content={[
                <h3 className="text-3xl text-gray-600">{t('platform.sections.14.title')}</h3>,
                <p className="subtitle text-gray-600">{t('platform.sections.14.subtitle')}</p>,
                <a className="subtitle text-blue" href={`mailto:${HrEmail}`}>
                  {HrEmail}
                </a>,
              ]}
            />
            <Box
              style={{ transform: 'translateZ(0)' }}
              variant="no-image"
              className="lg:col-span-8"
              content={[
                <h3 className="z-10 text-3xl relative">{t('platform.sections.15.title')}</h3>,
                <p className="z-10 subtitle relative">{t('platform.sections.15.subtitle')}</p>,
              ]}
              bg={
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0  min-h-full min-w-full max-w-[unset] lg:max-h-[460px] opacity-50"
                >
                  <source src="/_statics/videos/we_are_flottando.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              }
            />
          </div>
        </section>
        <section className="text-center">
          <div className="mx-auto flex justify-center">
            <ContentBox className="h-44 flex flex-col justify-center items-center w-fit">
              <StaticImage
                alt="geneva"
                src="../images/geneva.png"
                width={160}
                quality={95}
                layout="fixed"
                loading="lazy"
                placeholder="none"
              />
              <p className="body-1 mt-1">{t('platform.numbers.8.label')}</p>
            </ContentBox>
          </div>
        </section>
        <section>
          <div className="grid gap-4 mb-24">
            <h4 className="text-secondary">{t('needMoreHelp')}</h4>
            <h3>{t('commonQuestions')}</h3>
            <Question question={allFaqs[0]?.faqs[0]?.question} answer={allFaqs[0]?.faqs[0]?.answer} />
            <Question question={allFaqs[0]?.faqs[1]?.question} answer={allFaqs[0]?.faqs[1]?.answer} />
            <Question question={allFaqs[0]?.faqs[3]?.question} answer={allFaqs[0]?.faqs[3]?.answer} />
            <Question question={allFaqs[0]?.faqs[5]?.question} answer={allFaqs[0]?.faqs[5]?.answer} />
            <Link className="body-1 font-semibold text-primary flex items-center" to="/faq">
              {t('visitFaq')}
              <BsArrowRightShort size={28} />
            </Link>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = ({ pageContext }: any) => {
  const translations = pageContext.translations
  const language = pageContext.language

  return (
    <>
      <title>{translations['platform.meta.title']}</title>

      <meta name="description" content={translations['platform.meta.description']} />
      <meta name="robots" content="index, follow" />
      <LanguageHeaders pathname={pageContext.defaultPath} locale={language} />
    </>
  )
}
