import classNames from 'classnames'
import throttle from 'lodash.throttle'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { ColorsPrimary } from '../constants'

interface Props {
  content: string[]
  highlightedWordsIndex: number[]
  className?: string
}

const AnimatedTitle: FC<Props> = ({ content, className, highlightedWordsIndex }) => {
  const titleRef = useRef<HTMLHeadingElement>(null)

  const [titleDelta, setTitleDelta] = useState<1 | -1>(1)

  const animateImage = useCallback(
    throttle(() => {
      const titleTop = titleRef.current?.getBoundingClientRect().top
      if (!titleTop || !window.screen.height) {
        return
      }

      const delta = titleTop - window.screen.height / 2 > 0 ? 1 : -1

      setTitleDelta(delta)
    }, 5),
    []
  )

  useEffect(() => {
    if (!window) {
      return
    }

    window.addEventListener('scroll', animateImage)
    return () => {
      window.removeEventListener('scroll', animateImage)
    }
  })

  useEffect(() => {}, [])

  return (
    <p ref={titleRef} className={classNames(className)}>
      {content.map((c, index) => (
        <span
          key={index}
          className="transition-colors duration-500 text-5xl sm:text-[5rem] font-bold leading-tight"
          style={{
            color:
              highlightedWordsIndex.includes(index) &&
              (highlightedWordsIndex.indexOf(index) % 2 === 0 || titleDelta === -1) &&
              !(highlightedWordsIndex.indexOf(index) % 2 === 0 && titleDelta === -1)
                ? ColorsPrimary
                : '#ffffff',
          }}
        >
          {c}{' '}
        </span>
      ))}
    </p>
  )
}

export default AnimatedTitle
