import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React, { FC, useLayoutEffect, useRef, useState } from 'react'

import { useTranslate } from '../utils/translate'

const HomeVehicleIllustration: FC = () => {
  const headerSectionContainer = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const [sectionHeaderTop, setSectionHeaderTop] = useState(0)

  const t = useTranslate()

  useLayoutEffect(() => {
    if (!window) {
      return
    }
    const setTop = () => {
      if (window.scrollY > 1000) {
        return
      }
      setSectionHeaderTop(window.scrollY / 3)
    }
    const setW = () => {
      setWidth(window.innerWidth)
    }

    setW()

    window.addEventListener('scroll', setTop)
    window.addEventListener('resize', setW)

    return () => {
      window.removeEventListener('resize', setW)
      window.removeEventListener('scroll', setTop)
    }
  }, [])

  const pointA = (width - 1024) / 2
  const pointB = (width - 1024) / 2 + 1024
  const curveRadius = 80

  return (
    <div className="relative lg:min-h-screen flex flex-col justify-center text-center pt-8 lg:0">
      <section ref={headerSectionContainer}>
        <div
          id="overview-section"
          className="w-full lg:max-w-screen-lg mx-auto grid grid-flow-row gap-6 items-center will-change-[top,opacity,filter]"
          style={{
            transform: `translateY(${sectionHeaderTop}px)`,
            opacity: sectionHeaderTop < 75 ? 1 : 1 - (sectionHeaderTop - 75) / 100 || 0,
            filter: `blur(${sectionHeaderTop < 75 ? 0 : (sectionHeaderTop - 100) / 5}px`,
          }}
        >
          <p className="subtitle text-secondary font-bold">{t('platform.headers.1.headline')}</p>
          <h1>{t('platform.headers.1.title')}</h1>
          <p className="subtitle lg:mx-4">{t('platform.headers.1.subtitle')}</p>
        </div>
        <div className="h-[350px]">
          <div
            className={classNames(
              'z-0 sm:block absolute w-full overflow-hidden h-[350px] left-0 right-0 select-none transition-opacity opacity-0 -translate-y-40 lg:-translate-y-32',
              { ['!opacity-100']: width !== 0 }
            )}
          >
            <div className={`absolute mt-[184px] left-[calc(50%-142px)] w-[284px] h-[190px]`}>
              <StaticImage
                alt="van top view"
                src="../images/van_top.png"
                layout="fixed"
                quality={90}
                height={190}
                width={284}
                placeholder="blurred"
                loading="eager"
                imgClassName="select-none"
              />
            </div>
            <svg width={width} viewBox={`0 0 ${width} 300`} fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d={`M0 140 H ${pointA - curveRadius} Q ${pointA} 140 ${pointA} ${280 - curveRadius} Q ${pointA} 280 ${
                  pointA + curveRadius
                } 280 H ${pointB - curveRadius} Q ${pointB} 280 ${pointB} ${
                  280 - curveRadius
                } V ${curveRadius} Q ${pointB} 20 ${pointB + curveRadius} 20 H ${width}`}
                stroke="url(#paint0_radial_486_1049)"
                strokeWidth={curveRadius / 2}
              />

              <defs>
                <radialGradient
                  id="paint0_radial_486_1049"
                  cx="48%"
                  cy={280}
                  r={width / 2}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#01FCFE" />
                  <stop offset="10%" stopColor="#3CF3FF" />
                  <stop offset="50%" stopColor="#018FFD" />
                  <stop offset="100%" stopColor="#011859" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HomeVehicleIllustration
